@use 'sass:list';
@import 'vars';

// ================================================
// GPU fix
// ================================================
@mixin gpu {
  backface-visibility: hidden;
  transform-style: preserve-3d;
  perspective: 1000px;

  .safari & {
    perspective: none; // otherwise text and icons become blurry
  }
}

// ================================================
// Same height/width box
// ================================================
@mixin box($width, $height: $width) {
  width: $width;
  height: $height;
}

// ================================================
// REM calc
// ================================================
@function px($px) {
  @return $px * 0.1rem;
}

// ================================================
// Top left positioning
// ================================================
@mixin position-0 {
  top: 0;
  left: 0;
}

// ================================================
// Full width/height positioning
// ================================================
@mixin position-100($pos: relative) {
  position: $pos;
  @include position-0;
  @include box(100%);
}

// ================================================
// Center things horizontally in flexbox
// ================================================
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

// ================================================
// Center things vertically in flexbox
// ================================================
@mixin flex-center-vert {
  display: flex;
  align-items: center;
}

// ================================================
// Center something with abs/fixed positioning
// ================================================
@mixin transform-center {
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

// ================================================
// Z-Index for main components
// ================================================
@mixin z-index($id) {
  z-index: list.index($elements, $id) + 100;
}

// ================================================
// Media queries
// ================================================
@mixin bp($size, $is-max-width: false) {
  @if $is-max-width {
    @media (max-width: map-get($layout, $size) + px) {
      @content;
    }
  } @else {
    @media (min-width: map-get($layout, $size) + px) {
      @content;
    }
  }
}

@mixin bp-custom($query, $is-max-width: false) {
  @if $is-max-width {
    @media (max-width: #{$query}) {
      @content;
    }
  } @else {
    @media (min-width: #{$query}) {
      @content;
    }
  }
}

@mixin bp-height($px, $is-max-width: false) {
  @if $is-max-width {
    @media (max-height: #{$px}px) {
      @content;
    }
  } @else {
    @media (min-height: #{$px}px) {
      @content;
    }
  }
}

// ================================================
// Hover
// ================================================
@mixin hover {
  @media (pointer: fine) {
    &:hover {
      @content;
    }
  }
}

// ================================================
// Reset ul
// ================================================
@mixin reset-ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

// ================================================
// Reset button
// ================================================
@mixin reset-button {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  text-align: inherit;
  letter-spacing: inherit;
  font-size: inherit;
  text-transform: inherit;
  display: inline-block;
  margin: 0;
  text-decoration: none;
  appearance: none;
  color: currentColor;
}

// ================================================
// Container
// ================================================

@mixin container {
  margin: 0 auto;
  padding: 0 var(--gutter);
  width: 100%;
}

// ================================================
// Height
// ================================================

@mixin vh($vh, $property: null) {
  @if $property {
    #{$property}: #{$vh}svh;
    #{$property}: calc(var(--vh, 1vh) * #{$vh});

    body[data-browser='chrome'][data-device='mobile'] & {
      #{$property}: #{$vh}svh; // must be this vh on android
    }
  } @else {
    height: #{$vh}svh;
    height: calc(var(--vh, 1vh) * #{$vh});

    body[data-browser='chrome'][data-device='mobile'] & {
      height: #{$vh}svh; // must be this vh on android
    }
  }
}

// ================================================
// Hide scrollbar
// ================================================

@mixin hide-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

// ================================================
// Background Gradient
// ================================================

@mixin bg-gradient {
  background: linear-gradient(12.04deg, #2626c7 -100%, #080891 30%, #010d1e 100%);
}

@mixin video-gradient {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 80%) 0%,
    rgba(0, 0, 0, 0%) 30%,
    rgba(0, 0, 0, 0%) 70%,
    rgba(0, 0, 0, 80%) 100%
  );
}
