@use 'sass:map';

// ================================================
// Breakpoints
// ================================================
$layout: (
  mobile: 768,
  tablet: 967,
  laptop: 1200,
  desktop: 1512,
  xl: 1800,
);
$layout-mobile: map.get($layout, mobile) + px;
$layout-tablet: map.get($layout, tablet) + px;
$layout-laptop: map.get($layout, laptop) + px;
$layout-desktop: map.get($layout, desktop) + px;
$layout-xl: map.get($layout, xl) + px;

// ================================================
// Colors
// ================================================

// General
$white: #fcfcfc;
$white-off: #ececec;
$black: #000;
$gold: #ba913e;
$blue-light: #e2ebf6;
$blue-faded: #a0a7d1;
$blue-dark: #090952;
$blue-dark-faded: #5e5e8d;
$blue-dark-faded-inverse: #585888;
$blue-grey: #f0f0f3;

// ================================================
// General
// ================================================

$gutter-px: 24;
$gutter: #{px($gutter-px)};

// ================================================
// Fonts
// ================================================

$display-font-name: 'OptimaLTPro';
$body-font-name: 'Roboto';
$display-fonts: $display-font-name, sans-serif;
$body-fonts: $body-font-name, sans-serif;

// ================================================
// z-index data (see z-index mixin)
// ================================================
$elements: white_bg, main, navigation, modal, desktop_messaging;
