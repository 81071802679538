@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.Navigation {
  @include z-index(navigation);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  --fg: #{$white};

  &.whiteBg {
    --fg: #{$blue-dark};
  }
}

.topLeftContainer {
  top: var(--header-height);
  left: var(--gutter);
  position: absolute;
  transform: translateY(-100%);
}

.cta {
  @include reset-button;

  [data-label] {
    transition: color 0.2s;
    color: var(--fg);
  }
}

.logoContainer {
  position: absolute;
  left: 50%;
  top: var(--header-height);
  transform: translate(-50%, -100%);
  pointer-events: none;
}

.logo {
  display: block;
  width: px(100);
  height: auto;
  transition: opacity 0.8s 0.4s;

  .isFullVideo & {
    transition: opacity 0.2s 0s;
    opacity: 0;
  }
}
