@import "@/styles/shared";
.Grain {
  @include position-100(absolute);
  @include z-index(grain);
  pointer-events: none;
}

.texture {
  pointer-events: none;
  background-image: url('/assets/grain.png');
  background-repeat: repeat;
  height: 300%;
  left: -100%;
  position: absolute;
  top: -100%;
  width: 300%;
  will-change: transform;
  z-index: 3;
  opacity: 0.04;

  :global {
    animation: grain 6s steps(7) infinite;
  }
}
