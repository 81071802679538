@mixin font-h1 {
  font-family: $display-fonts;
  font-style: normal;
  font-weight: 500;
  font-size: px(40);
  line-height: 110%;
  letter-spacing: -0.03em;
}

@mixin font-h2 {
  font-family: $display-fonts;
  font-weight: 500;
  font-size: px(32);
  line-height: 130%;
  letter-spacing: -0.03em;
}

@mixin font-h3 {
  font-family: $display-fonts;
  font-style: normal;
  font-weight: 500;
  font-size: px(24);
  line-height: 130%;
  letter-spacing: -0.02em;
}

@mixin font-h4 {
  font-family: $display-fonts;
  font-style: normal;
  font-weight: 600;
  font-size: px(21);
  line-height: 150%;
  letter-spacing: -0.01em;
}

@mixin font-h5 {
  font-family: $display-fonts;
  font-style: normal;
  font-weight: 600;
  font-size: px(15);
  line-height: 170%;
}

@mixin font-input-fields {
  font-family: $display-fonts;
  font-style: normal;
  font-weight: 500;
  font-size: px(32);
  line-height: 110%;
  letter-spacing: -0.03em;
}

@mixin font-body {
  font-family: $body-fonts;
  font-style: normal;
  font-weight: 400;
  font-size: px(17);
  line-height: 150%;
}

@mixin font-body-small {
  font-family: $body-fonts;
  font-style: normal;
  font-weight: 400;
  font-size: px(13);
  line-height: 150%;
}

@mixin font-cta {
  font-family: $display-fonts;
  font-style: normal;
  font-weight: 500;
  font-size: px(16);
  line-height: 100%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

@mixin font-cta-secondary {
  font-family: $display-fonts;
  font-style: normal;
  font-weight: 700;
  font-size: px(13);
  line-height: 110%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
}

@mixin font-cta-tertiary {
  font-family: $body-fonts;
  font-style: normal;
  font-weight: 600;
  font-size: px(13);
  line-height: 150%;
}

@mixin font-nav {
  font-family: $body-fonts;
  font-style: normal;
  font-weight: 600;
  font-size: px(13);
  line-height: 110%;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

@mixin font-instructions {
  font-family: $body-fonts;
  font-style: normal;
  font-weight: 600;
  font-size: px(13);
  line-height: 150%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}

@mixin font-eyebrow {
  font-family: $body-fonts;
  font-style: normal;
  font-weight: 600;
  font-size: px(11);
  line-height: 140%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

@mixin font-h1-desktop {
  font-family: $display-fonts;
  font-weight: 500;
  font-size: px(56);
  line-height: 110%;
  letter-spacing: -0.03em;
}

@mixin font-h2-desktop {
  font-family: $display-fonts;
  font-weight: 500;
  font-size: px(36);
  line-height: 130%;
  letter-spacing: -0.02em;
}
