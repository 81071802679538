@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.SlideUpModal {
  @include position-100(fixed);
  @include z-index(modal);
  pointer-events: none;

  &.modalIsOpen {
    pointer-events: all;
  }
}

.bg {
  @include position-100(absolute);
  background-color: $blue-dark;
  opacity: 0;
}

.modalContent {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  padding: 0 px(6);
  transform: translateY(100%);

  @include bp(mobile) {
    padding: 0;
    transform: translateX(100%);
    left: auto;
    right: 0;
    width: px(366);
    height: 100%;
  }
}

.modalContent__inner {
  width: 100%;
  background-color: $white;
  color: $blue-dark;
  border-top-right-radius: px(30);
  border-top-left-radius: px(30);
  padding: px(72) px(24) px(40);
  position: relative;

  @include bp(mobile) {
    border-radius: 0;
    height: 100%;
  }
}

.storesContent__heading {
  @include font-h4;
  text-align: center;
  max-width: px(249);
  margin: 0 auto px(24) auto;
}

.storesContent__location {
  @include font-body;
  color: $blue-dark-faded;

  &:not(:last-child) {
    margin-bottom: px(24);
  }

  b {
    color: $blue-dark;
  }
}

.howThisWorksContent__title {
  @include font-h4;
  text-align: center;
  margin-bottom: px(18);
}

.howThisWorksContent__list {
  @include reset-ul;
  padding-left: px(24);
  position: relative;
}

.howThisWorksContent__listItem {
  @include font-body;
  margin-bottom: px(5);
  color: $blue-dark-faded;
}

.howThisWorksContent__listItemNumber {
  position: absolute;
  left: 0;
}

.closeButton {
  @include reset-button;
  @include font-nav;
  @include flex-center;
  line-height: 1;
  padding: px(5) px(11);
  background-color: $blue-grey;
  border-radius: px(30);
  position: absolute;
  top: px(16);
  right: px(24);
}

.xIcon {
  width: px(10);
  margin-left: px(6);
}
