@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.Button {
  @include reset-button;
  transition: opacity 0.3s;

  --primary-bg: #{$white};
  --primary-text: #{$blue-dark};
  --secondary-text: #{$white};

  &.inverse {
    --primary-bg: #{$blue-dark};
    --primary-text: #{$white};
    --secondary-text: #{$blue-dark};
  }

  &[disabled] {
    opacity: 0.15;
  }

  &.primary {
    @include font-cta;
    @include flex-center;
    height: px(64);
    position: relative;
    width: px(315);
    color: $blue-dark;
    max-width: 100%;
  }

  &.secondary {
    @include font-cta-secondary;
  }

  &.pill {
    @include font-nav;
    padding: px(5) px(15);
    background-color: $blue-dark;
    color: $white;
    border-radius: px(30);

    &.inverse {
      background-color: $white;
      color: $blue-dark;
    }
  }

  &.diamond {
    @include box(px(65));
    @include flex-center;
    position: relative;

    &::after {
      @include position-100(absolute);
      display: block;
      content: '';
      pointer-events: none;
      transform: rotate(-135deg);
      border-radius: px(12);
      border: px(2) solid $white;
      z-index: 0;
    }

    &.diamondFilled {
      &::after {
        background-color: $white;
      }
    }

    &.inverse {
      color: $blue-dark;

      &::after {
        border-color: $blue-dark;
        opacity: 0.2;
      }
    }
  }
}

.primaryButtonSvgShape {
  @include position-100(absolute);
  display: block;
}

.outlinePath {
  stroke: $gold;
}

.outlineBg {
  fill: var(--primary-bg);
}

.label {
  @include flex-center;
  gap: px(7);
  position: relative;
  z-index: 2;
  color: var(--secondary-text);

  .primary & {
    margin-bottom: px(-5);
    color: var(--primary-text);
    gap: px(9);
  }

  .swapButtonLabelPosition & {
    order: 1;
  }
}

.iconContainer {
  width: px(6);
  position: relative;
  z-index: 2;

  .swapButtonLabelPosition & {
    order: 2;
  }

  &[data-icon='retry'] {
    width: px(14);
  }

  &[data-icon='x'] {
    width: px(9);
  }

  .primary & {
    width: px(10);
  }

  .diamond & {
    width: px(16);

    &[data-icon='checkmark'] {
      margin-bottom: px(-3);
    }

    &[data-icon='play'] {
      margin-right: px(-6);
      margin-bottom: px(-4);
    }

    &[data-icon='pause'] {
      width: px(13);
      margin-bottom: px(-3);
    }
  }

  .diamondFilled & {
    color: $blue-dark;
  }
}
