@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.StarsVideo {
  position: absolute;
  max-width: 100%;
  z-index: -1;
  pointer-events: none;
}

.video,
.image {
  @include position-100;
  max-width: 100%;
  display: block;
  background: transparent;
  width: 100%;
  object-fit: contain;
}

// .video {
// opacity: 0;
// transition: opacity 0.2s;

// .loadedVideoData & {
//   opacity: 1;
// }
// }

.isLowPowerMode {
  @include position-100(absolute);
  @include flex-center;
}
