@import "@/styles/shared";
.starsVideo__2 {
  bottom: 0;
  left: 0;

  [data-media] {
    width: 160px;

    @include bp(tablet) {
      width: 416px;
    }
  }
}

.starsVideo__1 {
  top: 0;
  right: 0;

  [data-media] {
    width: 160px;

    @include bp(tablet) {
      width: 416px;
    }
  }
}
