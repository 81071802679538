@import "@/styles/shared";
// Global styling, normalize overwritten
@import 'fonts';
@import 'shared';

*,
*::before,
*::after {
  box-sizing: inherit;
}

:root {
  --gutter: #{$gutter};
  --header-height: #{px(55)};
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $white;
  scroll-behavior: initial;
  min-height: 100%;
  height: auto;
  font-size: 10px;
}

body {
  @include bg-gradient;
  font-family: $body-fonts;
  margin: 0;
  padding: 0;
  min-height: 100%;
  font-size: px(16);
  height: auto;
  color: $white;

  @include hide-scrollbar;

  //   @include position-100(fixed);
  //   @include vh(100);
  //   overflow: hidden;

  // Disabling interaction
  &[data-can-interact='false'] {
    pointer-events: none !important;

    * {
      pointer-events: none !important;
    }
  }
}

main {
  @include z-index(main);
  @include vh(100, min-height);
  position: relative;

  [data-browser='chrome'][data-device='mobile'] & {
    min-height: 100vh; // fix for android/chrome browsers
  }
}

a {
  color: inherit;
  text-decoration: none;
}

ul,
dl,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

li,
dt,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote {
  margin: 0;
  padding: 0;
}

/* ====================================
Grain Animation
==================================== */

@keyframes grain {
  0% {
    transform: translate(20%, -15%);
  }

  10% {
    transform: translate(-20%, -15%);
  }

  20% {
    transform: translate(20%, -5%);
  }

  30% {
    transform: translate(-20%, -5%);
  }

  40% {
    transform: translate(20%, 5%);
  }

  50% {
    transform: translate(-20%, 5%);
  }

  60% {
    transform: translate(20%, 15%);
  }

  70% {
    transform: translate(-20%, 15%);
  }

  80% {
    transform: translate(20%, 5%);
  }

  90% {
    transform: translate(-20%, 5%);
  }

  100% {
    transform: translate(20%, -5%);
  }
}
