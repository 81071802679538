@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.WhiteBg {
  @include position-100(fixed);
  @include z-index(white_bg);
}

.innerBg {
  @include position-100(absolute);
  background-color: $white-off;
  opacity: 0;
}
